<template>
    <AppPaginatedGrid
        ref="grid"
        :service="service"
        permission="estrutura_cliente"
        newPath="/procedure/new"
        editPath="/procedure/"
        @onToggleMenu="onToggleMenu"
        :expander="false"
        subtitle="Procedimentos"
        tooltip="Cadastro de procedimentos"
        :tipo="tipoFiltroEnum.SISTEMA"
        :modulo="moduloFiltroEnum.PROCEDIMENTOS"
        descricao="FILTRO PADRÃO PROCEDIMENTOS"
        nomeTelaDoManual="procedure-list"
        origemHistoricoAcao="/procedures"
    >
        <template #columns>
            <Column field="id" :sortable="true" sortField="procedures.id" header="Cod."></Column>

            <Column field="name" :sortable="true" sortField="procedures.name" header="Nome do Procedimento"></Column>

            <Column field="createdAt" :sortable="true" sortField="procedures.createdAt" header="Criado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.createdAt) }}
                    <small class="block text-500"> por {{ slotProps.data.userCreate.name }} </small>
                </template>
            </Column>

            <Column field="updatedAt" :sortable="true" sortField="procedures.updatedAt" header="Atualizado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.createdAt) }}
                    <small class="block text-500"> por {{ slotProps.data.userUpdate.name }} </small>
                </template>
            </Column>

        </template
    ></AppPaginatedGrid>
</template>

<script>
import BaseService from '../../services/BaseService';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';
import ModuloFiltroEnum from '../../enums/ModuloFiltroEnum';
import TipoFiltroEnum from '../../enums/TipoFiltroEnum';

export default {
    data() {
        return {
            service: null,
        };
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        },
        moduloFiltroEnum() {
            return ModuloFiltroEnum;
        },
        tipoFiltroEnum() {
            return TipoFiltroEnum;
        }
    },
    watch: {
        tenant() {
            this.load();
        },
        currentCustomer() {
            this.tenantCustomers = getCurrentCustomers();
            this.load();
        }
    },
    created() {
        this.service = new BaseService('/procedures');
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        },
    }
};
</script>
